// @ts-nocheck
import React, { useState } from 'react';
import { EditBase, TopToolbar, SimpleForm, TextInput, SelectInput, ReferenceManyField, Datagrid, TextField, useRecordContext, FunctionField } from 'react-admin';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { countryChoices } from '../../Services/billingAccount';
import BillingAccountUserModalCreate from './BillingAccountUsers/BillingAccountUserModalCreate';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';


const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Billing Account / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const BillingAccountEdit = (props: any) => {
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
  <EditBase {...props}>
    <React.Fragment>
    <Breadcrumb />
    {/* Utilisation d'une grille à deux colonnes */}
    <Grid container spacing={2}>
      {/* Colonne principale avec les projets associés */}
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Associated Users
              </Typography>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                    +
              </Button>
            </div>

            <ReferenceManyField
              label="Users"
              reference="billing-account-users"
              target="billingAccountId"
              sort={{ field: '"User"."name"', order: 'ASC' }}
            >
              <Datagrid>
                <TextField source="user.name" label="User" />
                <TextField source="role" label="Role" />
                <FunctionField render={record => <ConfirmDeleteButton 
                  record={record} 
                  title='Delete billing account user' 
                  entity='billing-account-users'
                  description='Are you sure to delete this user ?'
                  withRedirect={false}
                  confirmMessage='User is deleted' />
                  } />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
      </Grid>

      <RecordContextWrapper>
      {record => (
        <BillingAccountUserModalCreate open={open} handleClose={handleClose} billingAccountId={record?.id} />
      )}
      </RecordContextWrapper>

      {/* Colonne secondaire avec le formulaire utilisateur */}
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Billing Account Details
            </Typography>
            <SimpleForm>
              <TextInput source="id" disabled />
              <TextInput source="name" />
              <TextInput source="organisationName" />
              <TextInput source="adress" />
              <TextInput source="zipCode" />
              <TextInput source="city" />
              <SelectInput source="country" choices={countryChoices} optionText="name" optionValue="id" />
              <TextInput source="phone" />
              <TextInput source="billEmail" />
              <TextInput source="vat" />
            </SimpleForm>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </React.Fragment>
  </EditBase>
)};

const RecordContextWrapper: React.FC<{ children: (record: any) => React.ReactNode }> = ({ children }) => {
  const record = useRecordContext();
  
  if (!record) {
    return null; // Retournez null ou un spinner si le record est encore en cours de chargement
  }

  return <>{children(record)}</>;
};
