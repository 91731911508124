


export const countryChoices = [
  { id: "US", name: "United States" },
  { id: "CA", name: "Canada" },
  { id: "GB", name: "United Kingdom" },
  { id: "FR", name: "France" },
  { id: "DE", name: "Germany" },
  { id: "IT", name: "Italy" },
  { id: "ES", name: "Spain" },
  { id: "JP", name: "Japan" },
  { id: "CN", name: "China" },
  { id: "IN", name: "India" },
  { id: "BR", name: "Brazil" },
  { id: "AU", name: "Australia" },
  { id: "MX", name: "Mexico" },
  { id: "RU", name: "Russia" },
  { id: "ZA", name: "South Africa" },
  { id: "KR", name: "South Korea" },
  { id: "AR", name: "Argentina" },
  { id: "CH", name: "Switzerland" },
  { id: "SE", name: "Sweden" },
  { id: "NL", name: "Netherlands" }
];


