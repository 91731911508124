import React from 'react';
import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { countryChoices } from '../../Services/billingAccount';

export const BillingAccountCreate = (props:any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="organisationName" />
      <TextInput source="adress" />
      <TextInput source="zipCode" />
      <TextInput source="city" />
      <SelectInput source="country" choices={countryChoices} optionText="name" optionValue="id" />
      <TextInput source="phone" />
      <TextInput source="billEmail" />
      <TextInput source="vat" />
      
    </SimpleForm>
  </Create>
);