import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, FunctionField } from 'react-admin';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';


const BillingAccountFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
export const BillingAccountList = (props: any) => (
  <List {...props} filters={<BillingAccountFilter />}>
    <Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
      <TextField source="name" />
      <TextField source="organisationName" />
      <TextField source="city" />
      <TextField source="phone" />
      <EditButton label="Edit" />
      <FunctionField render={record => <ConfirmDeleteButton 
        record={record} 
        title='Delete billing account' 
        entity='billing-accounts'
        description='Are you sure to delete this billing account ?'
        confirmMessage='Billing account is deleted' />
        } />
    </Datagrid>
  </List>
);
