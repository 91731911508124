import React from 'react';
import { List, Filter, TextInput, Datagrid, TextField } from 'react-admin';


const ProjectFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);


export const ProjectList = (props: any) => (
  <List {...props} filters={<ProjectFilter />}>
    <Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="folderId" />
      <TextField source="projectDatawareHouseId" />
      <TextField source="accountEmail" />
      <TextField source="region" />
    </Datagrid>
  </List>
);