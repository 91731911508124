import React from 'react';
import { Confirm, Button, useDelete, useNotify, useRefresh, useRedirect } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

interface ConfirmDeleteButtonProps {
  record: any, 
  title: string, 
  description: string, 
  entity: string, 
  confirmMessage: string, 
  withRedirect?: boolean
}

const ConfirmDeleteButton = ({ record, title, description, entity, confirmMessage, withRedirect = true }: ConfirmDeleteButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [deleteOne, { isLoading }] = useDelete();

  const handleConfirm = () => {
    deleteOne(
      entity,
      { id: record.id, previousData: record },
      {
        onSuccess: () => {
          notify(confirmMessage, { type: 'info' });
          if (withRedirect) redirect('list', entity);
          refresh();
        },
        onError: (error: any) => {
          notify(`Error: ${error.message}`, { type: 'warning' });
        }
      }
    );
    setOpen(false);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Button
        label="Delete"
        color='error'
        onClick={handleClick}
        disabled={isLoading}
      >
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={open}
        title={title}
        content={description}
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ConfirmDeleteButton;