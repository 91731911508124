import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const w: any = window;
const endPoint = `${w.API_CORE}/v1/admin`;

const authToken = localStorage.getItem('token'); // Récupérer le token enregistré

// Personnaliser la fonction de requête
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // Ajouter l'en-tête `Authorization` si un token est présent
  if (authToken) {
    options.headers.set('Authorization', `Bearer ${authToken}`);
  }

  return fetchUtils.fetchJson(url, options);
};

// Créer le `dataProvider` personnalisé avec le `httpClient`
export const apiCoreProvider = simpleRestProvider(endPoint, httpClient);
